import axios from "axios";
import instance from "./instance";
import { toastr } from 'react-redux-toastr'


export const listAllPlayerPoint = () => {
    return instance.get()
}

export const getPlayerPointByUuid = (id) => {
    const url = `/${id}`
    return instance.get(url)
}

//  NẠP THẺ API BODY.REQ
//     "username": "HinaLink",
//       "content": "abcxyz",
//       "menhgia": "10000",
//       "mathe": "mathene",
//       "seri": "serine",
//       "type": "Viettel"
export const napThe = (infoCard) => {
    const url = `/napthe`
    return instance.post(url, infoCard)
}

export const logCoinLimit = () => {
    const url = `/logcoin/`
    return instance.get(url)
}

export const getAllLogCoinAdmin = () => {
    const url = `/alllogcoin/`
    return instance.get(url)
}

export const getTop10NapTheAdmin = () => {
    const url = `/top10/`
    return instance.get(url)
}


export const callBackNapThe = () => {
    return instance.post()
}