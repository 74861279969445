import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLogCoin, getTop10NapThe } from '../../features/Point/pointSlice'

const Top10BuyCoin = () => {
    const dispatch = useDispatch()
    const dataLogCoin = useSelector((store) => store.point.top10)

    useEffect(() => {
        dispatch(getTop10NapThe())
    }, [])
    return (
        <div>
            <h2>Top 10 nạp thẻ</h2>
            <div className="tw-my-3 tw-relative tw-overflow-x-auto tw-shadow-md sm:tw-rounded-lg">
                <table className="tw-w-full tw-text-sm tw-text-left">
                    <thead className="tw-bg-gray-500">
                        <tr>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                #
                            </th>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                Tên nhân vật
                            </th>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                Số lần nạp
                            </th>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                Tiền Nạp
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataLogCoin && dataLogCoin.length > 0 && dataLogCoin.map((data, index) => (
                                <tr className="tw-border-b" key={ index }>
                                    <th scope="row" className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap">
                                        { index + 1 }
                                    </th>
                                    <th scope="row" className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap">
                                        { data.name }
                                    </th>
                                    <td className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap ">
                                        { data.soLanNap }
                                    </td>
                                    <td className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap">
                                        <span className="tw-font-bold tw-bg-green-400 tw-p-1 tw-text-green-800 tw-rounded">{ data.TongTienNap }</span>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Top10BuyCoin