import React from 'react'
import Logo from './Components/Logo'
import Nav from './Components/Nav'

const Header = (props) => {
  const onHandleChangeNick = (data) => {
    props.onHandleChangeNick(data)
  }
  const onTurnOffLogOut = (data) => {
    props.onTurnOffLogOut(data)
  }
  return (
    <>
      <Logo />
      <Nav toggleDangXuat={ props.toggleDangXuat } onChangeNickName={ onHandleChangeNick } onTurnOffLogOut={ onTurnOffLogOut } />
    </>
  )
}

export default Header