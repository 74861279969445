import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllLogCoinLimit } from '../../../../features/Point/pointSlice'

const TableBuyCoin = () => {
    const dispatch = useDispatch()
    const dataLogCoin = useSelector((store) => store.point.data)
    useEffect(() => {
        dispatch(getAllLogCoinLimit())
    }, [])

    return (
        <div className="!tw-mb-10">
            <div className="tw-relative tw-h-[580px] tw-overflow-auto tw-shadow-md sm:tw-rounded-lg">
                <table className="tw-w-full tw-text-sm tw-text-left">
                    <thead className="tw-bg-[#F36250]">
                        <tr>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                Tên nhân vật
                            </th>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                Seri
                            </th>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                Mệnh giá
                            </th>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                Thẻ
                            </th>
                            <th scope="col" className="tw-px-6 tw-py-3 tw-text-white">
                                Trạng thái
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataLogCoin && dataLogCoin.length > 0 && dataLogCoin.map(data => (
                                <tr className="tw-border-b" key={ data.id }>
                                    <th scope="row" className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap ">
                                        { data?.name }
                                    </th>
                                    <td className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap ">
                                        { data?.seri.slice(0, 8) }***
                                    </td>
                                    <td className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap ">
                                        { data?.amount }
                                    </td>
                                    <td className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap ">
                                        { data?.type }
                                    </td>
                                    <td className="tw-px-6 tw-py-4 tw-font-bold tw-whitespace-nowrap ">
                                        { data?.status === 0 && <span className="tw-font-bold tw-bg-orange-400 tw-p-1 tw-text-orange-800 tw-rounded">Chờ Xác Nhận</span> }
                                        { data?.status === 1 && <span className="tw-font-bold tw-bg-green-400 tw-p-1 tw-text-green-800 tw-rounded">Thành Công</span> }
                                        { data?.status === 2 && <span className="tw-font-bold tw-bg-red-400 tw-p-1 tw-text-red-800 tw-rounded">Thất Bại</span> }
                                        { data?.status === 3 && <span className="tw-font-bold tw-bg-red-400 tw-p-1 tw-text-red-800 tw-rounded">Sai Mệnh Giá</span> }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TableBuyCoin