import { configureStore } from '@reduxjs/toolkit';
import { reducer as toastrReducer } from 'react-redux-toastr'
import pointReducer from '../features/Point/pointSlice';

export const store = configureStore({
  reducer: {
    toastr: toastrReducer,
    point: pointReducer,
  },
});
