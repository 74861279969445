import React from 'react'
import { Navigate } from 'react-router-dom';

const PrivateRouter = (props) => {
    const playerStorage = JSON.parse(localStorage.getItem("user"))
    if (playerStorage) {
        if (!playerStorage?.username) {
            return <Navigate to="/" />
        } else {
            return props.children
        }

    } else {
        return <Navigate to="/" />
    }
}

export default PrivateRouter