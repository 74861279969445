import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { BrowserRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr'

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={ store }>
      <BrowserRouter>
        <ReduxToastr
          timeOut={ 5000 }
          newestOnTop={ false }
          preventDuplicates
          position="top-right"
          getState={ (state) => state.toastr } // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick />
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
