import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom'
import './App.css';
import PrivateRouter from './components/PrivateRouter';
import Dashboard from './pages/admin/Dashboard';
import ListBuyCoin from './pages/admin/ListBuyCoin';
import Top10BuyCoin from './pages/admin/Top10BuyCoin';
import BuyCoin from './pages/client/BuyCoin';
import Login from './pages/client/Login';
import AdminLayout from './pages/layouts/AdminLayout';
import WebsiteLayout from './pages/layouts/WebsiteLayout';
import Page404 from './pages/Page404';

function App() {
  const [player, setPlayer] = useState("")
  const [playerShowLogOut, setPlayerShowLogOut] = useState(false)

  const handleOnlogin = (data) => {
    setPlayerShowLogOut(data)
    const playerStorage = JSON.parse(localStorage.getItem("user"))
    if (playerStorage) {
      setPlayer(playerStorage.username)
    } else {
      setPlayer("Chưa đăng nhập")
    }
  }

  const onHandleChangeNick = (data) => {
    setPlayer(data)
  }

  const onTurnOffLogOut = (data) => {
    console.log("LOGOUT", data);
    setPlayerShowLogOut(data)
  }

  useEffect(() => {
    const playerStorage = JSON.parse(localStorage.getItem("user"))
    if (playerStorage) {
      setPlayer(playerStorage.username)
      setPlayerShowLogOut(true)
    } else {
      setPlayer("Chưa đăng nhập")
      setPlayerShowLogOut(false)
    }
  }, [player, playerShowLogOut])

  return (
    <Routes>
      {/* Website */ }
      <Route path="/" element={
        <WebsiteLayout
          toggleDangXuat={ playerShowLogOut }
          onHandleChangeNick={ onHandleChangeNick }
          onTurnOffLogOut={ onTurnOffLogOut }
        /> }>
        <Route index element={ <Login onLogin={ handleOnlogin } /> } />
        <Route path="/login" element={ <Login onLogin={ handleOnlogin } /> } />
        <Route path="/nap-the" element={ <PrivateRouter><BuyCoin player={ player } /></PrivateRouter> } />
      </Route>

      <Route path="admin/" element={ <AdminLayout /> }>
        <Route index element={ <Navigate to="dashboard" /> } />
        <Route path="dashboard" element={ <Dashboard /> } />
        <Route path="danh-sach-nap-the" element={ <ListBuyCoin /> } />
        <Route path="top-10" element={ <Top10BuyCoin /> } />
      </Route>

      <Route path="*" element={ <Page404 /> } />
    </Routes>
  );
}

export default App;
