import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { napTheSieuToc } from '../../../../api/coin';
import { napTheApi, serverTheSieuTocNapThe } from '../../../../features/Point/pointSlice';

const FormBuyCoin = (props) => {
    const dispatch = useDispatch()
    const [typeCard, settypeCard] = useState("NullCard")
    const [menhGia, setMenhGia] = useState(0)
    const { register, handleSubmit, formState: { errors } } = useForm()

    const handleChangeTypeCard = (event) => {
        settypeCard(event.target.value)
    }
    const handleChangeMenhGia = (event) => {
        setMenhGia(event.target.value)
    }



    const onSubmitCard = (data) => {
        const { username } = JSON.parse(localStorage.getItem('user'))

        const toastrConfirmOptions = {
            onOk: () => {
                const dataCard = {
                    username,
                    menhgia: menhGia,
                    type: typeCard,
                    mathe: data.mathe,
                    seri: data.seri
                }
                dispatch(napTheApi(dataCard))
            },
            onCancel: () => { }
        };
        toastr.confirm(`Card ${typeCard} Tên ${username}  Giá  ${menhGia} VNĐ  Seri ${data.seri} Pin ${data.mathe}`, toastrConfirmOptions)

    }

    return (
        <div className="md:tw-col-span-2 tw-bg-thi tw-rounded-[var(--rd-pri)] tw-p-8 tw-px-9 sm:tw-px-16 md:tw-px-40">
            <div className="tw-text-center ">
                <h3 className="size-larger">Mua Coin Tự Động</h3>
                <span className="tw-block size-large tw-text-danger tw-font-bold">Bạn đang nạp coin vào tài khoản</span>
                <span className="tw-block size-large tw-text-danger tw-font-[900]">{ props.player }</span>
            </div>
            <form onSubmit={ handleSubmit(onSubmitCard) } action="" className="tw-block tw-m-auto tw-mt-3 tw-space-y-4">
                <select onChange={ handleChangeTypeCard } className="form__input tw-text-pri-text" name="" id="">
                    <option value="NullCard" className="tw-py-2">Chọn loại thẻ</option>
                    <option value="Viettel" className="tw-py-2">Viettel</option>
                    <option value="Mobifone" className="tw-py-2">Mobifone</option>
                    <option value="Vinaphone" className="tw-py-2">Vinaphone</option>
                    <option value="Zing" className="tw-py-2">Zing</option>
                    <option value="Vietnamobile" className="tw-py-2">Vietnamobile</option>
                    <option value="Vcoin" className="tw-py-2">Vcoin</option>
                    <option value="Gate" className="tw-py-2">Gate</option>
                </select>
                <select onChange={ handleChangeMenhGia } className="form__input tw-text-pri-text" name="" id="">
                    <option value="0" className="tw-py-2">Chọn mệnh giá</option>
                    <option value="10000" className="tw-py-2">10.000</option>
                    <option value="20000" className="tw-py-2">20.000</option>
                    <option value="30000" className="tw-py-2">30.000</option>
                    <option value="50000" className="tw-py-2">50.000</option>
                    <option value="100000" className="tw-py-2">100.000</option>
                    <option value="200000" className="tw-py-2">200.000</option>
                    <option value="300000" className="tw-py-2">300.000</option>
                    <option value="500000" className="tw-py-2">500.000</option>
                    <option value="1000000" className="tw-py-2">1.000.000</option>
                </select>
                <div className="tw-relative">
                    <input className="form__input" placeholder=' ' type="text" { ...register('seri', { required: true }) } />
                    <label className="form__label">Số Seri</label>
                </div>
                <div className="tw-relative">
                    <input className="form__input" placeholder=' ' type="text" { ...register('mathe', { required: true }) } />
                    <label className="form__label">Mã thẻ</label>
                </div>
                <button className="btn-primary size-small">
                    NẠP COIN
                </button>
            </form>
        </div>
    )
}

export default FormBuyCoin