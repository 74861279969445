import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllLogCoinAdmin, getTop10NapTheAdmin, logCoinLimit, napThe } from "../../api/coin";
import { toastr } from 'react-redux-toastr'

export const napTheApi = createAsyncThunk(
    "point/napthe",
    async (dataCard, thunkAPI) => {
        try {
            const { data } = await napThe(dataCard);
            toastr.message(data.title, data.msg)
            return data;
        } catch (error) {
            toastr.warning(error.response.data.msg)
        }
    }
)

export const getAllLogCoinLimit = createAsyncThunk(
    "point/logcoin",
    async (username, thunkAPI) => {
        try {
            const { data } = await logCoinLimit();
            return data;
        } catch (error) {
            console.log(error);
        }
    }
)

export const getAllLogCoin = createAsyncThunk(
    "point/logcoinAdmin",
    async (username, thunkAPI) => {
        try {
            const { data } = await getAllLogCoinAdmin();
            return data;
        } catch (error) {
            console.log(error);
        }
    }
)

export const getTop10NapThe = createAsyncThunk(
    "point/top10",
    async (username, thunkAPI) => {
        try {
            const { data } = await getTop10NapTheAdmin();
            return data;
        } catch (error) {
            console.log(error);
        }
    }
)

const pointSlice = createSlice({
    name: "point",
    initialState: {
        data: [],
        allLogCoin: [],
        top10: []
    },

    reducers: {

    },

    extraReducers: (builder) => {
        builder.addCase(napTheApi.fulfilled, (state, action) => {
            // Khi người dùng bấm nút nạp thẻ
        })
        builder.addCase(getAllLogCoinLimit.fulfilled, (state, action) => {
            state.data = action.payload
        })
        builder.addCase(getAllLogCoin.fulfilled, (state, action) => {
            state.allLogCoin = action.payload
        })
        builder.addCase(getTop10NapThe.fulfilled, (state, action) => {
            state.top10 = action.payload
        })


    }

})

export default pointSlice.reducer;